<template>
	<Slideshow
		ref="slideShowRef"
		class="block-slideshow"
		:class="{
			'block-slideshow--full-height-builder':
				slideshowSettings.isMobileFullScreenHeightEnabled && isMobileView
		}"
		:style="computedStyles"
		:slides-count="slidesCount"
		:current-slide-index="currentSlideIndex"
		:is-navigation-arrows-visible="slideshowSettings.isNavigationArrowsVisible"
		:is-navigation-bullets-visible="slideshowSettings.isNavigationBulletsVisible"
		:is-autoplay-enabled="slideshowSettings.isAutoplayEnabled"
		:is-loop-enabled="slideshowSettings.isLoopEnabled"
		:is-next-slide-navigation-visible="isNextSlideNavigationVisible"
		:is-previous-slide-navigation-visible="isPreviousSlideNavigationVisible"
		:autoplay-slides-interval-seconds="slideshowSettings.autoplaySlidesIntervalSeconds"
		:is-mobile-view="isMobileView"
		:current-element-id="currentElementId"
		@previous-slide:click="goToPreviousSlide"
		@next-slide:click="goToNextSlide"
		@indicator:click="goToSlide"
	>
		<Slide
			v-for="(slide, index) in slides"
			:key="slide.blockId"
			:is-active="currentSlideIndex === index"
			:sliding-direction="currentSlidingDirection"
			@transition-start="setIsSlideTransitioning(true)"
			@transition-end="setIsSlideTransitioning(false)"
			@disable-transition-after-ms="setIsSlideTransitioningFalseAfterMs($event)"
		>
			<BlockBackground
				:background="slide.data.background"
				:is-next-to-transparent-header="isNextToTransparentHeader"
				:is-fixed="slide.data.settings.styles.attachment === 'fixed'"
				:style="backgroundImageOffset"
			/>
			<BlockGrid
				:block-id="slide.blockId"
				:data="slide.data"
				:is-block-hovered="isBlockHovered"
				:active-event-info="getBlockGridActiveEventInfo(slide.blockId)"
				@rows-changed="updateSlideshowRows"
				@update-mobile-padding="updateSlideshowMobilePadding"
				@set-edit-control-visibility="$emit('set-edit-control-visibility', $event)"
				@lock-hovered-block="$emit('lock-hovered-block', $event)"
				@drag-status-change="$emit('drag-status-change', $event)"
				@hover-background="$emit('hover-background', $event)"
			/>
		</Slide>
	</Slideshow>
</template>
<script>
import {
	onMounted,
	onUnmounted,
} from '@vue/composition-api';
import {
	mapMutations,
	mapState,
} from 'vuex';

import BlockGrid from '@/components/block-grid/BlockGrid.vue';
import Slide from '@user/components/block-slideshow/misc/Slide.vue';
import Slideshow from '@user/components/block-slideshow/misc/Slideshow.vue';
import {
	DIRECTION_LEFT,
	useBlockSlideshow,
} from '@user/components/block-slideshow/use/useBlockSlideshow';
import BlockBackground from '@user/components/block/BlockBackground.vue';
import { IPHONE_PREVIEW_HEIGHT } from '@user/constants';

export default {
	components: {
		Slideshow,
		BlockBackground,
		Slide,
		BlockGrid,
	},
	props: {
		blocks: {
			type: Object,
			required: true,
		},
		data: {
			type: Object,
			required: true,
		},
		blockId: {
			type: String,
			required: true,
		},
		activeEventInfo: {
			type: Object,
			default: () => ({}),
		},
		isBlockHovered: {
			type: Boolean,
			default: false,
		},
		isNextToTransparentHeader: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const {
			removeActiveSlide,
			setActiveSlide,
			setIsSlideTransitioning,
			setSlidingDirection,
			setIsSlideTransitioningFalseAfterMs,
			slideshowSettings,
			currentSlideIndex,
			currentSlidingDirection,
			slides,
			slidesCount,
			goToSlide,
			goToNextSlide,
			goToPreviousSlide,
			isNextSlideNavigationVisible,
			isPreviousSlideNavigationVisible,
			isSlideshowTransitioning,
		} = useBlockSlideshow(props);

		onMounted(() => {
			/**
			 * Set default active slide on mount - first slide
			 */
			setActiveSlide({
				slideshowId: props.blockId,
				slideId: props.blocks[props.blockId].slides[0].blockId,
			});
			setSlidingDirection(DIRECTION_LEFT);
			setIsSlideTransitioning(false);
		});

		onUnmounted(() => {
			/**
			 * Removing slideshow key value when block is delete to prevent
			 * memory leak
			 */
			removeActiveSlide(props.blockId);
		});

		return {
			slideshowSettings,
			currentSlideIndex,
			currentSlidingDirection,
			setIsSlideTransitioningFalseAfterMs,
			slides,
			slidesCount,
			isNextSlideNavigationVisible,
			isPreviousSlideNavigationVisible,
			setIsSlideTransitioning,
			isSlideshowTransitioning,
			goToSlide,
			goToNextSlide,
			goToPreviousSlide,
		};
	},
	computed: {
		...mapState('gui', ['isMobileView']),
		...mapState([
			'website',
			'currentElementId',
		]),
		...mapState('navigation', ['navigationHeight']),
		computedStyles() {
			const offsetStyle = this.isNextToTransparentHeader ? {
				paddingTop: `${this.navigationHeight}px`,
				marginTop: `${-this.navigationHeight}px`,
			} : {};

			return {
				'--m-blockMobileMinHeight': `${IPHONE_PREVIEW_HEIGHT}px`,
				...offsetStyle,
			};
		},
		backgroundImageOffset({ isSlideshowTransitioning }) {
			const {
				top,
				left,
			} = this.$refs.slideShowRef?.$el?.getBoundingClientRect() ?? {};

			return {
				'--imageOffsetTop': isSlideshowTransitioning ? `${-top}px` : 0,
				'--imageOffsetLeft': isSlideshowTransitioning ? `${-left}px` : 0,
			};
		},
	},
	methods: {
		...mapMutations(['setBlockData']),
		updateSlideshowRows(rows) {
			if (rows <= this.data.settings.styles.rows) {
				return;
			}

			this.setBlockData({
				blockId: this.blockId,
				data: { settings: { styles: { rows } } },
				skipHistory: false,
			});
		},
		updateSlideshowMobilePadding(padding) {
			if (padding === this.data.settings.styles['m-block-padding']) {
				return;
			}

			this.setBlockData({
				blockId: this.blockId,
				data: { settings: { styles: { 'm-block-padding': padding } } },
				skipHistory: false,
			});
		},
		getBlockGridActiveEventInfo(blockGridId) {
			return this.activeEventInfo && this.activeEventInfo.blockId === this.blockId
				? { blockId: blockGridId }
				: {};
		},
	},
};
</script>

<style scoped lang="scss">
@import '@user/components/block-slideshow/BlockSlideshow.scss';
</style>
